/**
 * Sentry DSN.
 *
 * Leave empty to disable Sentry.
 */
export const SENTRY_DSN = 'https://1d6b98abb755b837251990dd8adf2f95@sentry.naukosfera.com/5'

/**
 * Release version of Sentry.
 */
export const SENTRY_RELEASE = '7af3835935683acd90a93b9b179036de7f590cc8'
