import * as React from 'react'
import { Editor, Transforms } from 'slate'
import { Localized } from '@fluent/react'
import { Admonition, Code, Preformat, Problem, Statement } from 'cnx-designer'
// import { useSlate } from 'slate-react'
import { Marker, Suggestion, SuggestionsEditor } from 'slate-suggestions'
import { SlotPermission } from '../../../../../../../../api/process'
import Button from '../../../../../../../../components/ui/Button'
import { Options } from '../types'
import { resolveFromMarker } from '../utils'
import acceptImg from '../../../../../../../../assets/images/accept_sug.svg'
import rejectImg from '../../../../../../../../assets/images/reject_sug.svg'

import './index.css'

interface SuggestionBoxProps {
  editor: SuggestionsEditor,
  suggestion: Suggestion | Marker
  draftPermissions: SlotPermission[]
  changeSelectionOnAccRej: boolean
  onAccept: (editor: SuggestionsEditor, type: Options) => void
  onDecline: (editor: SuggestionsEditor, type: Options) => void
  selectNextOrPrev: (mode: "next" | "prev") => void
}

const SuggestionBox = (props: SuggestionBoxProps) => {
  const { editor, suggestion, draftPermissions, changeSelectionOnAccRej, onAccept, onDecline, selectNextOrPrev } = props

  // const editor = useSlate()
  // const [[insertContent, deleteContent]/* , setContent */] = React.useState(['', ''])

  const onClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    // ev.preventDefault()
    // props.onClick(props.suggestion)
  }

  // React.useEffect(() => {
  // let mounted = true
  // getSuggestionContent(editor, props.suggestion, props.getString)
  //   .then(content => {
  //     if (mounted) { setContent(content) }
  //   })
  //   .catch(() => {
  //     if (mounted) { setContent(['', '']) }
  //   })
  // return () => { mounted = false }
  // // Recalculate only on props.suggestion or editor.selection update
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.suggestion, editor.selection])

  // const localizedId = 'editor-tools-suggestion-insert'
  // if (props.suggestion.type === 'remove') {
  //   localizedId = 'editor-tools-suggestion-remove'
  // } else if (props.suggestion.type === 'change') {
  //   localizedId = 'editor-tools-suggestion-change'
  // }

  const onClickAccept = React.useCallback(ev => {
    ev.stopPropagation()
    const [path, type] = resolveFromMarker(editor, suggestion[1], suggestion[0])
    if (changeSelectionOnAccRej && !Editor.above(editor, { at: path, match: n => Suggestion.is(n) })) {
      selectNextOrPrev("next")
    }
    onAccept(editor, { at: path, type })
  // eslint-disable-next-line
}, [editor, onAccept, suggestion, changeSelectionOnAccRej])

  const onClickReject = React.useCallback(ev => {
    ev.stopPropagation()

    const above = Editor.above(editor, {
      at: suggestion[1],
      match: n =>
        Suggestion.is(n) &&
        (Admonition.isAdmonition(n) || Code.isCode(n) || Preformat.isPreformat(n) || Statement.isStatement(n) || Problem.isProblem(n)),
    })
    const [path, type] = resolveFromMarker(editor, suggestion[1], suggestion[0])
    if (changeSelectionOnAccRej && !above ) {
      selectNextOrPrev("next")
      onDecline(editor, { at: path, type })
    } else if (above) {
      onDecline(editor, { at: path, type })
      if (!editor.selection) return
      Transforms.select(editor, Editor.end(editor, editor.selection.anchor.path))
      Transforms.move(editor, { distance: 1, unit: 'character' })
    } else onDecline(editor, { at: path, type })

  }, [editor, onDecline, suggestion, changeSelectionOnAccRej])

  return (
    <div id="suggestion-box" className="suggestion-box" onClick={onClick}>
      <div className="suggestion-box__buttons">
        <div className='suggestion-box__description'>
          <Localized
            id="editor-tools-suggestion-description"
            vars={{
              suggestion: suggestion[0].suggestion,
              type: suggestion[0].type ?? 'text',
            }}
          />
        </div>
        <div>
          {
            draftPermissions.includes('accept-changes') ?
              <Button id="editor-tools-suggestion-accept" clickHandler={onClickAccept}>
                <img src={acceptImg} alt="accept" />
              </Button>
              : null
          }
          <Button id="editor-tools-suggestion-reject" clickHandler={onClickReject}>
            <img src={rejectImg} alt="reject" />
          </Button>
        </div>
      </div>
      <div className="suggestion-box__info">
        {suggestion[0].text}
        {/* <Localized
          id={localizedId}
          vars={{ insert: insertContent, remove: deleteContent }}
          elems={{ action: <strong/>, content: <span/> }}
        > */}
        {/* Required to properly render actions and contents as html elements. */}
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <></>
        {/* </Localized> */}
      </div>
    </div>
  )
}

// export default withLocalization(SuggestionBox)
export default SuggestionBox
