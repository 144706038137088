import * as React from 'react'
import { Localized } from '@fluent/react'
import { FilesError } from 'react-files'
import * as api from '../../api'
import Spinner from '../../components/Spinner'
import Dialog from '../../components/ui/Dialog'
import Input from '../../components/ui/Input'
import FilesUploader from '../../containers/FilesUploader'
import store from '../../store'
import { addAlert } from '../../store/actions/alerts'

interface EditBookProps {
  book: api.Book
  onClose: () => any
}

class EditBook extends React.Component<EditBookProps> {
  state: {
    isLoading: boolean
    titleInput: string
  } = {
      isLoading: false,
      titleInput: '',
    }

  private editBook = (e: React.FormEvent) => {
    e.preventDefault()

    this.setState({ isLoading: true })

    const { titleInput: title } = this.state
    const { book } = this.props

    const payload = {
      title,
    }

    book.update(payload)
      .then(() => {
        store.dispatch(addAlert('success', 'book-edit-alert-success'))
      })
      .catch(e => {
        store.dispatch(addAlert('error', e.message))
      })
      .finally(() => {
        this.props.onClose()
      })
  }

  private updateTitleInput = (val: string) => {
    this.setState({ titleInput: val })
  }

  public render() {
    const { isLoading, titleInput } = this.state
    const bookTitle = this.props.book.title

    return (
      <Dialog
        l10nId="book-edit-dialog-title"
        placeholder="Edit book"
        size="medium"
        onClose={this.props.onClose}
      >
        {
          !isLoading ?
            <form onSubmit={this.editBook}>
              <Input
                type="text"
                l10nId="book-edit-title"
                value={bookTitle}
                onChange={this.updateTitleInput}
                minLength={3}
                required
              />
              <div className="dialog__buttons dialog__buttons--center">
                <Localized id="book-edit-submit" attrs={{ value: true }}>
                  <input
                    type="submit"
                    value="Confirm"
                    disabled={titleInput.length === 0}
                  />
                </Localized>
              </div>
            </form>
            : <Spinner/>
        }
      </Dialog>
    )
  }
}

export default EditBook
